.modal-lg-custom {
  max-width: 900px !important;
}

.modal_range_date_selection,
.modal_range_date_selection_export {
  ._input_datepicker_only_picker {
    .ngb-dp-months {
      display: none !important;
    }
  }

  .modal_range_date_selection_calendar {
    .rect_inner {
      fill: var(--blackBg) !important;
    }
  }

  .ngb-dp-day.disabled {
    @extend .text-muted;
    opacity: 0.4;
  }

  .input-group {
    ngb-datepicker {
      top: 40px !important;
    }
  }
  ._input_datepicker_month {
    select {
      margin: 1em !important;
      min-width: 100px;
    }
    .ngb-dp-arrow {
      display: none !important;
    }
    ngb-datepicker {
      border: none !important;
    }
    .ngb-dp-header {
      background: transparent !important;
    }
    .ngb-dp-navigation-select {
      padding: 0em !important;
    }
    .custom-select {
      margin: 0 !important;
    }
    .text_blue {
      * > .custom-select {
        font-weight: bold !important;
        color: var(--primaryBlue) !important;
        * > {
          color: black;
        }
      }
    }
    .text_orange {
      * > .custom-select {
        font-weight: bold !important;
        color: var(--primaryOrange) !important;
        * > {
          color: black;
        }
      }
    }
  }

  ._input_datepicker_year {
    select:first-child {
      display: none !important;
    }
    select {
      margin: 1em !important;
      min-width: 100px;
    }
    .ngb-dp-arrow {
      display: none !important;
    }
    ngb-datepicker {
      border: none !important;
    }
    .ngb-dp-header {
      background: transparent !important;
    }
    .ngb-dp-navigation-select {
      padding: 0em !important;
    }
    .custom-select {
      margin: 0 !important;
    }
    .text_blue {
      * > .custom-select {
        font-weight: bold !important;
        color: var(--primaryBlue) !important;
        * > {
          color: black;
        }
      }
    }
    .text_orange {
      * > .custom-select {
        font-weight: bold !important;
        color: var(--primaryOrange) !important;
        * > {
          color: black;
        }
      }
    }
  }
}
