@import '/node_modules/rfs/scss';

.logout_nav {
  bottom: 10px;
}

.navbar_dropdown {
  cursor: auto !important;
  > .collapse {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    transform: unset !important;
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .dropdown-item:hover,
  .dropdown-item:active,
  .dropdown-item:focus {
    background: transparent !important;
  }
}

.scrollable_items_navbar,
.scrollable_items_navbar_small {
  background: transparent !important;
  > * {
    background: transparent !important;
  }
}

._nav_global_action {
  position: absolute !important;
  width: 100% !important;
  bottom: 0em !important;
  padding: 0.5em !important;
  border-top: solid 1px #80808073 !important;
  border-radius: unset !important;
}

._nav_scroll {
  max-height: 70vh !important;
  overflow-x: hidden !important;
}

.navbar_dropdown .dropdown-toggle::after {
  display: none !important;
}
