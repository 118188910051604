@import '/node_modules/rfs/scss';
.bg_grey {
  background-color: var(--grey) !important;
}

._absolute-top {
  position: absolute;
  top: 0;
}

._invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

ng-select.is-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  > .ng-value-container {
    background: transparent;
  }
}

ng-select.is-valid.ng-touched .ng-select-container {
  border-color: #28a745;
  > .ng-value-container {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 0.75rem center/8px 10px no-repeat,
      #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
        center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
  }
}

.inprogress {
  opacity: 0.5;
}

.bg_black {
  background-color: var(--blackBg) !important;
}

.bg_red {
  background-color: var(--primaryRed) !important;
}

.bg_disabled {
  background-color: var(--disabledBg) !important;
}

._border_light {
  border-color: #0000004f !important;
}

._text_suffix {
  display: flex;
  align-items: end;
}

._max-h-content {
  max-height: -webkit-max-content !important;
  max-height: -moz-max-content !important;
  max-height: max-content !important;
  height: -webkit-max-content !important;
  height: -moz-max-content !important;
  height: max-content !important;
}

.journey-table,
.short-table {
  * > .datatable-body {
    height: 50vh !important;
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  * > .datatable-footer {
    display: none !important;
  }

  * > .datatable-body-cell-label {
    text-align: center !important;
  }
}

.datatable_modal_rank_place {
  top: 10px;
  right: 35px;
  > img {
    width: 50px;
    height: 50px;
  }
}

.leaflet-popup {
  min-width: 330px;
}

.leaflet-popup-content {
  min-width: 320px;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  @extend ._row_selected;
}

._row_selected {
  background: var(--bgTableOrangeSelected) !important;
}

.text_red {
  color: var(--primaryRed) !important;
}

.text_orange {
  color: var(--primaryOrange) !important;
}
.text_blue {
  color: var(--primaryBlue) !important;
}

._bg_orange {
  background-color: var(--primaryOrange) !important;
}
._bg_blue {
  background-color: var(--primaryBlue) !important;
}

._hover_primary_blue:focus,
._hover_primary_blue:hover {
  background-color: #024f5d36 !important;
}

._hover_primary_orange:focus,
._hover_primary_orange:hover {
  background-color: #cb8f005e !important;
}

._active_primary_blue:active {
  background-color: #024f5dde !important;
}

._active_primary_orange:active {
  background-color: #cb8f00eb !important;
}

._border_primary_blue {
  border: solid 3px var(--primaryBlue) !important;
}

._border_primary_orange {
  border: solid 3px var(--primaryOrange) !important;
}

.text_green {
  color: var(--primaryGreen) !important;
}

.text_bold {
  font-weight: bold !important;
}

.text_normal {
  font-weight: normal !important;
}

.element_forward {
  font-size: rfs(1.25rem);
}

._mx_150 {
  max-height: 150px !important;
  height: 150px !important;
}

.bg_green {
  background-color: var(--primaryGreen) !important;
}

.bg_active_black {
  background-color: var(--activeBlack);
}

.no-radius {
  border-radius: unset !important;
}
.no-border {
  border: unset !important;
}

.width-max-content {
  width: max-content !important;
}

.height-max-content {
  height: max-content !important;
}
.scrollable_details {
  max-height: 250px !important;
  height: 250px !important;
  max-width: 100% !important;
  width: auto !important;
  overflow-x: hidden !important;
}

.scrollable_items_navbar {
  max-height: 200px !important;

  max-width: 100% !important;
  width: auto !important;
  overflow-x: hidden !important;
}

.scrollable_items_navbar_small {
  max-height: 100px !important;
  height: 100px !important;
  max-width: 100% !important;
  width: auto !important;
  overflow-x: hidden !important;
}

.mh-30 {
  min-height: 300px !important;
}

.scrollable_creation_user {
  max-height: 200px !important;
  height: 200px !important;
  max-width: 100% !important;
  width: auto !important;
  overflow-x: hidden !important;
}

.bg_grey_dark {
  background-color: var(--fontGrey) !important;
}

.text_grey {
  color: var(--fontGrey) !important;
  > * {
    color: var(--fontGrey) !important;
  }
}

.text_grey_selected {
  color: var(--grey) !important;
  > * {
    color: var(--grey) !important;
  }
}

.text_clean,
.text_clean:hover {
  text-decoration: none !important;
  border: none !important;
}

.nav_active {
  background-color: var(--blueActive) !important;
}

// info
.jumbotron {
  background-color: white !important;
}

// input
.input_default:focus {
  outline: 0;
  box-shadow: unset !important;
  opacity: 1 !important;
}

.input_default {
  background: transparent !important;
  border: unset !important;
  border-radius: unset !important;
  border-bottom: 1px solid black !important;
  opacity: 0.6;
}

// btn
.btn_black {
  background: var(--black) !important;
  color: white !important;
}

.btn-link {
  color: var(--black) !important;
  text-decoration: none;
}

.btn-link:hover {
  color: var(--lightBlack) !important;
  text-decoration: underline;
}
