@import './variables.scss';
@import './layout.scss';
@import './pages/login.scss';
@import './pages/dashboard.scss';
@import './pages/login.scss';
@import './pages/comptage.scss';
@import './pages/parcours.scss';
@import './pages/classement.scss';
@import './navBar.scss';
@import './utils.scss';
@import './map.scss';
@import './checkbox.scss';
@import './animations.scss';
@import './calendar.scss';
@import './modals.scss';
@import './modals/range-selection.scss';
@import './materials/elevations.scss';
@import './materials/opacity.scss';

.ngx-background-spinner {
  width: 175px !important;
  height: 75px !important;
  text-align: center !important;
}
