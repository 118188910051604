:root {
  --blackBg: #1c1c1c; // or any global you wish to share with components
  --lightBlueBg: #e7f0f1;
  --black: #000;
  --lightBlack: #5f5f5f;
  --activeBlack: #2b2b2b;
  --activeOrange: #cc9209;
  --grey: #e5e5e5;
  --fontGrey: #707070;
  --blueActive: #21616a;
  --primaryOrange: #cb8f00;
  --primaryOrangeDemiTransparent: #cb8e0081;
  --bgTableOrangeSelected: #cc9209;
  --primaryBlueDemiTransparent: #024f5d7c;
  --primaryBlue: #024f5d;
  --primaryGreen: #7fb79b;
  --primaryRed: #c66969;
  --disabledBg: #c8c8c8;
}
