.pointer {
  cursor: pointer;
}

.pointer_active_bg_grey:hover {
  background-color: rgb(238, 238, 238) !important;
}

._scollable {
  overflow-y: auto;
  overflow-x: hidden;
}

._opacity_9 {
  opacity: 0.95 !important;
}
._opacity_4 {
  opacity: 0.4 !important;
}

._hide {
  width: 0;
  margin: 0;
  border: none !important;
  padding: 0;
  opacity: none !important;
}

._tooltip {
  min-width: 200px;
  .tooltip-inner {
    background-color: var(--blackBg);
    color: white;
    border-radius: 15px;
  }
}

.flex-demi {
  flex: 0.3;
}
.flex-kpi {
  display: inline-block;
  width: 200px;
  height: 150px;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

._inline-block {
  display: inline-block;
}
.w-max-content {
  width: max-content;
}

.height-inherit {
  height: inherit !important;
}

._full_cell_width {
  * > .datatable-body-cell-label {
    width: 100% !important;
  }
}
