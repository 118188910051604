.comptage_page {
  .card-kpi {
    width: 250px !important;
    margin: 0 !important;
  }
}

.allkpi {
  flex-wrap: nowrap !important;
  overflow-x: visible !important;
  overflow-y: hidden !important;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
