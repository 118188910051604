.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row {
  background-color: silver !important;
  color: black;
  font-style: italic;
}

.font-dark-green {
  color: #278a58 !important;
}

.font-dark-red {
  color: #c43e3e !important;
}

.classement_page {
  .datatable-scroll {
    position: relative !important;
  }

  .datatable-summary-row {
    position: absolute !important;
    bottom: 0 !important;
    top: auto !important;
    transform: translateY(50px) !important;
  }
}
