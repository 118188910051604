._parcours_active_button {
  background: var(--activeOrange) !important;
  color: white !important;
  border-color: silver !important;
}

._parcours_active_button:active {
  border-color: silver !important;
}

._summary_row_data {
  position: absolute;
  top: 0;
}

._journey_chart {
  position: absolute !important;
  #_chart_journey_table_kpi,
  #_chart_journey_table_kpi_vs {
    max-height: 50px;

    width: 100% !important;
    height: 100% !important;
  }
}

._parcours_active_button:focus {
  border-color: silver !important;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background: transparent !important;
  .datatable-body-cell {
    * {
      font-weight: bold !important;
    }
    * > .text_green,
    .text_red {
      font-weight: bolder !important;
    }
    background: var(--fontGrey) !important;
  }
}

.parcours_info {
  position: absolute;
  right: 0;
  bottom: 0;
  @include padding(0.25em);
}
/**
   * Progress bar animations
   */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
