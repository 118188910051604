@import '/node_modules/rfs/scss';

._range_selection_edit_date {
  position: absolute !important;
  top: 0px;
  bottom: auto;
  right: 20px;
  left: auto;
  * > svg {
    opacity: 0.5;

    @include rfs(12.5px, width);
    @include rfs(12.5px, height);
  }

  svg:hover {
    opacity: 1;
    @include rfs(14px, width);
    @include rfs(14px, height);
  }
}

._range_selection_edit_date_custom,
._range_selection_edit_date_custom_vs {
  position: absolute !important; // cause to bootstrap 5
  top: 5px;
  bottom: auto;
  right: 5px;
  left: auto;
  * > svg {
    opacity: 0.5;

    @include rfs(12.5px, width);
    @include rfs(12.5px, height);
  }

  svg:hover {
    opacity: 1;
    @include rfs(14px, width);
    @include rfs(14px, height);
  }
}
