/* You can add global styles to this file, and also import other style files */
* {
  font-family: Helvetica, sans-serif;
}

// color
$grey: #222222;
/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import './assets/scss/index.scss';
@import '../node_modules/rfs/scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

html,
body {
  background-color: white;
  height: 100%;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

app-root {
  display: block;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

* {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.flex_demi {
  flex: 0 0 85.333% !important;
  max-width: 85.333% !important;
}

._col_demi {
  flex: 0 0 14.667% !important;
  max-width: 14.667% !important;
}

.simplebar-content-wrapper {
  overflow: auto;
}

.simplebar-scrollbar::before {
  background-color: red;
}

.mw-350 {
  max-width: 350px !important;
}

.btn-light:focus {
  color: #000 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
  box-shadow: unset !important;
}
