._round_checkbox {
  position: relative;
}

._round_checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

._round_checkbox input[type='checkbox'] {
  visibility: hidden;
}

._round_checkbox input[type='checkbox']:checked + label {
  background-color: #6f7070;
  border-color: #6f7070;
}
