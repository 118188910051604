@import '/node_modules/rfs/scss';

.kpi_info {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: auto;
}
.kpi_stats {
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: auto;
}

.kpi_min_width {
  @include rfs(350px, max-width);
  @include rfs(185px, min-width);
  @include rfs(130px, max-height);
  @include rfs(130px, min-height);
}

.wrap_chart_icon {
  @include rfs(75px, min-width);
  @include padding(0.1em);
  > img {
    margin: auto;
    @include rfs(60px, max-width);
    @include rfs(40px, min-width);
  }
}

.wrap_kpi_icon {
  top: 0px;
  left: auto;
  right: 0;
  background-color: white;
  border-radius: rfs(0.75em);
  @include rfs(75px, min-width);
  @include padding(0.25em);
  > img {
    margin-right: 0px;
    margin-left: auto;
    @include rfs(35px, max-width);
    @include rfs(35px, min-width);
  }
}

.canvas_wrap_loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overflow_kpi {
  flex-wrap: nowrap !important;
  overflow-x: visible !important;
  overflow-y: hidden !important;
}

// action bar

.counting-action-bar {
  .is_selected {
    svg {
      width: 40px;
      height: 40px;
      .rect_inner {
        fill: var(--primaryOrange) !important;
      }
    }
  }
  .is_not_selected {
    svg {
      .rect_inner {
        fill: var(--activeBlack) !important;
      }
    }
  }
}

.canvas_wrap_loading_age_gender {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100px !important;
    height: 100px !important;
  }
}
