.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.custom-day._is_date_range {
  background-color: var(--primaryOrange) !important;
  color: white;
}

.custom-day._is_date_vs_range {
  background-color: var(--primaryBlue) !important;
  color: white;
}

.custom-day._is_date_faded {
  background-color: var(--primaryOrangeDemiTransparent) !important;
}

.custom-day._is_date_vs_faded {
  background-color: var(--primaryBlueDemiTransparent) !important;
}

._range_date_feedback {
  padding: 0.75em;
  min-height: 50px;
  border: none;
  border-bottom: 1px solid #6c757d67;
  background-color: transparent;
  width: 100%;
}

._range_export_calendar {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
